import * as React from "react"
import Layout from "../components/layout"
import { graphql } from 'gatsby'
import {Col, Container, Row, Ratio} from 'react-bootstrap'

const How = ({ data }) => {
  const how = data.allContentfulHowWeWorkPage.edges[0].node
  const videourl = how.videoLink.replace("watch?v=", "embed/");
  return (
    <Layout title="How Varmaorka works">
      <div className="py-offset">
        <Container>
          <Row className="align-items-center mb-md-5 mb-4 pb-md-2">
            <Col md={12}>
              <h2 className="section-title section-title-inner">{how.title}</h2>
            </Col>
          </Row>

          <Row className="justify-content-between mb-md-5 mb-4">
            <Col className="d-flex" md={10} lg={8}>
              <div className="icon">
                <img src={how.image1.file.url} alt={how.image1.title} />
              </div>
              <div className="icon-content">
                <h3 className="mb-3">{how.heading1}</h3>
                <p className="vh_line">{how.text1.text1}</p>
              </div>
            </Col>            
          </Row>
          <Row className="justify-content-between mb-md-5 mb-4">
            <Col className="d-flex" md={10} lg={8}>
              <div className="icon">
                <img src={how.image2.file.url} alt={how.image2.title} />
              </div>
              <div className="icon-content">
                <h3 className="mb-3">{how.heading2}</h3>
                <p className="vh_line">{how.text2.text2}</p>
              </div>
            </Col>            
          </Row>
          <Row className="justify-content-between mb-md-5 mb-4">
            <Col className="d-flex" md={10} lg={8}>
              <div className="icon">
                <img src={how.image3.file.url} alt={how.image3.title} />
              </div>
              <div className="icon-content">
                <h3 className="mb-3">{how.heading3}</h3>
                <p className="vh_line">{how.text3.text3}</p>
              </div>
            </Col>            
          </Row>

          <div className="py-100">
            <Row className="justify-content-center mb-md-5">
              <Col md={9}>
                <div className="section-title-middle mb-5 text-center">
                  <h3>{how.videoHeading}</h3>
                </div>
              </Col>
            </Row>

            <Ratio aspectRatio="16x9" className="mb-5">
              <embed type="image/svg+xml" src={videourl} />
            </Ratio>
          </div>
        </Container>
      </div>
    </Layout>
  )
}

export const homeQuery = graphql`
query($language: String!){
  allContentfulHowWeWorkPage(filter: {node_locale: {eq: $language}}){
    edges {
      node {
        heading1
        heading2
        heading3
        image1 {
          file {
            url
          }
          title
        }
        image2 {
          file {
            url
          }
          title
        }
        image3 {
          file {
            url
          }
          title
        }
        text1 {
          text1
        }
        text2 {
          text2
        }
        text3 {
          text3
        }
        title
        videoHeading
        videoLink
      }
    }
  }

  locales: allLocale(filter: {language: {eq: $language}}) {
    edges {
      node {
        ns
        data
        language
      }
    }
  }
}`;

export default How
